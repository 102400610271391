@import "../../assets/styles/utils";

.index-page{
  header .header{
    background-color: #000;
  }
}
.index-banner{
  @include res(margin-top, $header-height-base, $header-height-ratio);
  ul{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li{
      height: auto;
      overflow: hidden;
      &:first-child{
        @include res(width,50%,(xs:100%));
        a{
          .mask{
            background-image: linear-gradient(180deg,rgba(46,87,166,.77) 30%,hsla(0,0%,100%,0));
          }
        }
      }
      &:nth-child(2){
        @include res(width,25%,(xs:50%));
        a{
          .mask{
            background-image: linear-gradient(225deg,rgba(211,209,209,.77) 30%,hsla(0,0%,100%,0));
          }
        }
      }
      &:nth-child(3){
        @include res(width,25%,(xs:50%));
        a{
          .mask{
            background-image: linear-gradient(135deg,rgba(186,158,185,.77) 30%,hsla(0,0%,100%,0));
          }
        }
      }
      &:nth-child(4){
        @include res(width,25%,(xs:50%));
        a{
          .mask{
            background-image: linear-gradient(225deg,rgba(117,143,94,.77) 30%,hsla(0,0%,100%,0));
          }
        }
      }
      &:nth-child(5){
        @include res(width,25%,(xs:50%));
        a{
          .mask{
            background-image: linear-gradient(90deg,rgba(79,79,79,.77) 30%,hsla(0,0%,100%,0));
          }
        }
      }
      &:last-child{
        @include res(width,50%,(xs:100%));
        a{
          .mask{
            background-image: linear-gradient(180deg,rgba(199,148,90,.77) 30%,hsla(0,0%,100%,0));
          }
        }
      }
      &:hover{
        // 不显示视频只放大图片
        &:not(:nth-child(8)){
          a{
            img{
              opacity: 0;
            }
            video{
              opacity: 1;
            }
          }
        }
        a{
          img{
            transform: scale(1.1);
          }
        }
      }
      a{
        width: 100%;
        height: 100%;
        display: block;
        position: relative;
        img{
          width: 100%;
          height: 100%;
          display: block;
          margin: 0 auto;
          object-fit: cover;
          transition: 0.5s;
          position: absolute;
          top: 0;
          left: 0;
        }
        video{
          width: 100%;
          height: 100%;
          display: block;
          margin: 0 auto;
          object-fit: cover;
          opacity: 0;
          transition: 0.5s;
        }
        .mask{
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          p{
            @include res(font-size,.3rem,(xs:14px));
            color: #fff;
          }
        }
      }
    }
  }
}
.section1{
    position: relative;
    // @include res(padding-top,115px, 30 / 115);
    @include res(padding-top,1.15rem, (xs:30px));
    @include res(padding-bottom,330px,(lg:220px,mmd:170px,md:130px,sm:80px,xs:0));
    &::after{
        content: '';
        display: block;
        width: 100%;
        background: $color-green-light;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        @include res(height,180px, 0 / 180);
    }
    .left{
        position: relative;
        z-index: 1;
        .title{
            line-height: 1;
            @include res(text-align,null,(xs:center));
            // @include res(font-size,40px, 20 / 40);
            @include res(font-size,.4rem, (xs:20px));
            // @include res(margin-bottom,55px, 15 / 55);
            @include res(margin-bottom,.55rem, (xs:15px));
            p{
                margin-top: 10px;
                // @include res(font-size,60px, 20 / 60);
                @include res(font-size,.6rem, (xs:20px));
            }
        }
        .desc{
            @include res(width,370px);
            // @include res(padding-right,110px, 10 / 110);
            @include res(padding-right,1.1rem, (xs:10px));
            @include res(font-size,16px, 16 / 16);
            // @include res(margin-bottom,50px, 15 / 50);
            @include res(margin-bottom,.5rem, (xs:15px));
        }
        .link{
            @include res(text-align,null,(xs:center));
            a{
                display: inline-block;
                border: 1px solid #000;
                color: #666666;
                transition: all .25s;
                @include res(padding-left,25px, 15 / 25);
                @include res(padding-right,25px, 15 / 25);
                @include res(padding-top,5px, 5 / 5);
                @include res(padding-bottom,5px, 5 / 5);
                &:hover{
                    background: $color-green;
                    border: 1px solid #fff;
                    color: #fff;
                }
            }
        }
    }
    .right{
        bottom: 0;
        right: 0;
        z-index: 0;
        @include res(margin-top,0,(xs:20px));
        @include res(position,absolute,(xs:static));
        @include res(width,55.7%,(sm:53%,xs:100%));
        img{
            display: block;
            width: 100%;
            height: auto;
        }
    }
}

.section2{
    // @include res(margin-top,130px, 30 / 130);
    @include res(margin-top,1.3rem, (xs:30px));
    // @include res(margin-bottom,140px, 30 / 140);
    @include res(margin-bottom,1.4rem, (xs:30px));
    .title{
        text-align: center;
        // @include res(font-size,60px, 20 / 60);
        @include res(font-size,.6rem, (xs:20px));
        // @include res(margin-bottom,50px, 10 / 50);
        @include res(margin-bottom,.5rem, (xs:10px));
    }
    .swiper-container{
        // @include res(height,560px, 150 / 560);
        @include res(height,5.6rem, (xs:150px));
        .swiper-slide{
            transition: all .8s;
            transform: scale(0.8);
            position: relative;
            &.swiper-slide-duplicate-active,&.swiper-slide-active{
                transform: scale(1);
                a{
                  .item-desc{
                    span{
                      &:nth-child(1){
                          top: 42%;
                          left: 41%;
                      }
                      &:nth-child(2){
                          top: 42%;
                          left: 46%;
                      }
                      &:nth-child(3){
                          top: 42%;
                          left: 51%;
                      }
                      &:nth-child(4){
                          top: 42%;
                          left: 56%;
                      }
                    }
                  }
                }
                a{
                    .item-title,.item-desc{
                        opacity: 1;
                    }
                }
            }
            &.swiper-slide-prev,&.swiper-slide-next{
              &::after{
                content: '';
                width: 100%;
                height:100%;
                background:rgba(0,0,0,.5);
                position: absolute;
                left: 0;
                top:0;
                z-index: 9;
              }
            }
            a{
                display: flex;
                flex-direction: column;
                height: 100%;
                background-position: center;
                background-size: cover;
                align-items: center;
                justify-content: center;
                color: #f5fbf9;
                position: relative;
                &::after{
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0,0,0,.2);
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 0;
                }
                .item-title{
                    opacity: 0;
                    transition: all .5s;
                    position: relative;
                    z-index: 1;
                    // @include res(font-size,30px, 16 / 30);
                    @include res(font-size,.3rem, (xs:16px));
                }
                .item-desc{
                    transition: all .5s;
                    // @include res(font-size,40px, 20 / 40);
                    @include res(font-size,.4rem, (xs:20px));
                    span{
                        z-index: 1;
                        transition: all .35s ease-in-out;
                        @include res(position,absolute,(md:static));
                        color: #fff;
                        &:nth-child(1){
                            top: 2%;
                            left: 3%;
                        }
                        &:nth-child(2){
                            top: 2%;
                            left: 93%;
                        }
                        &:nth-child(3){
                            top: 84%;
                            left: 3%;
                        }
                        &:nth-child(4){
                            top: 84%;
                            left: 93%;
                        }
                    }
                }
            }
        }
    }
    .swiper-button-prev{
      @include res(left,26%, 2 / 26);
    }
    .swiper-button-next{
      @include res(right,26%, 2 / 26);
    }
    .swiper-button-prev,.swiper-button-next{
      color: #fff;
      @include res(width,50px, 30 / 50);
      @include res(height,50px, 30 / 50);
      background:rgba(0,0,0,.5);
      border-radius: 50%;
    }
    .swiper-button-next:after, .swiper-button-prev:after{
      @include res(font-size,20px, 12 / 20);
    }
}

.section3{
    position: relative;
    // @include res(margin-bottom,150px, 30 / 150);
    @include res(margin-bottom,1.5rem, (xs:30px));
    @include res(padding-bottom,290px,(lg:190px,mmd:170px,md:130px,sm:30px,xs:0));
    &::after{
        content: '';
        display: block;
        width: 100%;
        background: $color-green-light;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
        mix-blend-mode: multiply;
        // @include res(height,140px, 50 /140);
        @include res(height,1.4rem, (xs:50px));
    }
    .left-img{
        bottom: 0;
        left: 0;
        z-index: 0;
        @include res(margin-top,0,(xs:20px));
        @include res(position,absolute,(xs:static));
        @include res(width,56.25%,(sm:50%,xs:100%));
        img{
            display: block;
            width: 100%;
            height: auto;
        }
    }
    .container-fluid{
        display: flex;
        justify-content: flex-end;
    }
    .right-text{
        position: relative;
        z-index: 1;
        @include res(width,370px,(lg:420px,mmd:470px,md:370px,sm:330px,xs:100%));
        .title{
            color: $color-green-light;
            @include res(text-align,null,(xs:center));
            // @include res(font-size,40px, 20 / 40);
            @include res(font-size,.4rem, (xs:20px));
        }
        .desc{
            @include res(font-size,16px, 16 / 16);
            // @include res(margin-bottom,50px, 15 / 50);
            @include res(margin-bottom,.5rem, (xs:15px));
        }
        .link{
            @include res(text-align,null,(xs:center));
            a{
                display: inline-block;
                border: 1px solid #000;
                color: #666666;
                transition: all .25s;
                @include res(padding-left,25px, 15 / 25);
                @include res(padding-right,25px, 15 / 25);
                @include res(padding-top,5px, 5 / 5);
                @include res(padding-bottom,5px, 5 / 5);
                &:hover{
                    background: $color-green;
                    border: 1px solid #fff;
                    color: #fff;
                }
            }
        }
    }
}

.section4{
    position: relative;
    // @include res(padding-bottom,90px, 10 / 90);
    @include res(padding-bottom,.9rem, (xs:10px));
    &::after{
        content: '';
        display: block;
        width: 100%;
        background-color: #eaeaea;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        @include res(height,50%);
    }
    .title{
        text-align: center;
        // @include res(margin-bottom,50px, 10 / 50);
        @include res(margin-bottom,.5rem, (xs:10px));
        // @include res(font-size,60px, 20 / 60);
        @include res(font-size,.6rem, (xs:20px));
    }
    .news-list{
      // @include res(padding-left,80px, 10 / 80);
      @include res(padding-left,.8rem, (xs:10px));
      // @include res(padding-right,80px, 10 / 80);
      @include res(padding-right,.8rem, (xs:10px));
      ul{
        display:flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li{
          // @include res(margin-bottom,90px, 20 / 90);
          @include res(margin-bottom,.9rem, (xs:20px));
          @include res(width,30%,(sm:48%));
          transition: 0.5s;
          a{
            background: #f1f1f1;
            display:flex;
            flex-flow: column;
            width:100%;
            .left-img{
              flex-shrink: 0;
              background-position: center;
              background-size: cover;
              width:100%;
              img{
                display: block;
                width: 100%;
                height: 100%;
                margin: 0 auto;
                opacity: 0;
              }
            }
            .right-text{
              width:100%;
              @include res(padding-top,20px, 10 / 20);
              @include res(padding-right,20px, 10 / 20);
              @include res(padding-bottom,30px, 15 / 30);
              @include res(padding-left,20px, 10 / 20);
              .item-date{
                text-align: center;
              }
              .item-title{
                overflow : hidden;
                display:-webkit-box;
                -webkit-box-orient:vertical;
                line-height: 1.4;
                @include res(min-height,66px, 48 / 66);
                @include res(-webkit-line-clamp,2,(xs:1));
                @include res(margin-top,15px, 10 / 15);
                // @include res(font-size,20px, 16 / 20);
                @include res(font-size,.20rem, (xs:16px));
                text-align: center;
                @media screen and (max-width: 640px){
                    min-height: auto;
                }
              }
              .item-desc{
                overflow : hidden;
                display:-webkit-box;
                -webkit-box-orient:vertical;
                @include res(-webkit-line-clamp,3,(xs:2));
                // @include res(margin-top,30px, 15 / 30);
                @include res(margin-top,.3rem, (xs:15px));
                @include res(font-size,14px, 14 / 14);
                text-align: center;
              }
              .item-line{
                width: 20%;
                height: 2px;
                background: #000;
                margin: 20px auto 0;
              }
              .item-more{
                width: max-content;
                line-height: 2.6;
                text-align: center;
                color: #000;
                background: #fff;
                margin: 20px auto 0;
                @include res(font-size,14px, 14 / 14);
                border: 1px solid #000000;
                transition: 0.5s;
                padding: 0 22px;
              }
            }
          }
          &:hover{
            transform:scale(1.05);
            a{
              .right-text{
                .item-more{
                  border: 1px solid #62A937;
                  background: #62A937;
                  color: #fff;
                }
              }
            }
          }
        }
        .placeholder{
            @include res(width,30%,(sm:48%));
        }
      }
    }
    .more-link{
        text-align: center;
        font-weight: bold;
        line-height: 2.2;
        a{
            display: inline-block;
            border: 1px solid $color-green;
            transition: all .3s;
            color: $color-green;
            @include res(padding-left,25px, 10 / 25);
            @include res(padding-right,25px, 10 / 25);
            &:hover{
                color: #fff;
                background: $color-green;
            }
        }
    }
}
