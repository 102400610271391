.index-page header .header {
  background-color: #000;
}

.index-banner {
  margin-top: 105px;
}

@media (max-width: 1689px) {
  .index-banner {
    margin-top: 97.5px;
  }
}

@media (max-width: 1599px) {
  .index-banner {
    margin-top: 90px;
  }
}

@media (max-width: 1359px) {
  .index-banner {
    margin-top: 82.5px;
  }
}

@media (max-width: 1024px) {
  .index-banner {
    margin-top: 75px;
  }
}

@media (max-width: 991px) {
  .index-banner {
    margin-top: 67.5px;
  }
}

@media (max-width: 767px) {
  .index-banner {
    margin-top: 60px;
  }
}

.index-banner ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.index-banner ul li {
  height: auto;
  overflow: hidden;
}

.index-banner ul li:first-child {
  width: 50%;
}

@media (max-width: 767px) {
  .index-banner ul li:first-child {
    width: 100%;
  }
}

.index-banner ul li:first-child a .mask {
  background-image: linear-gradient(180deg, rgba(46, 87, 166, 0.77) 30%, rgba(255, 255, 255, 0));
}

.index-banner ul li:nth-child(2) {
  width: 25%;
}

@media (max-width: 767px) {
  .index-banner ul li:nth-child(2) {
    width: 50%;
  }
}

.index-banner ul li:nth-child(2) a .mask {
  background-image: linear-gradient(225deg, rgba(211, 209, 209, 0.77) 30%, rgba(255, 255, 255, 0));
}

.index-banner ul li:nth-child(3) {
  width: 25%;
}

@media (max-width: 767px) {
  .index-banner ul li:nth-child(3) {
    width: 50%;
  }
}

.index-banner ul li:nth-child(3) a .mask {
  background-image: linear-gradient(135deg, rgba(186, 158, 185, 0.77) 30%, rgba(255, 255, 255, 0));
}

.index-banner ul li:nth-child(4) {
  width: 25%;
}

@media (max-width: 767px) {
  .index-banner ul li:nth-child(4) {
    width: 50%;
  }
}

.index-banner ul li:nth-child(4) a .mask {
  background-image: linear-gradient(225deg, rgba(117, 143, 94, 0.77) 30%, rgba(255, 255, 255, 0));
}

.index-banner ul li:nth-child(5) {
  width: 25%;
}

@media (max-width: 767px) {
  .index-banner ul li:nth-child(5) {
    width: 50%;
  }
}

.index-banner ul li:nth-child(5) a .mask {
  background-image: linear-gradient(90deg, rgba(79, 79, 79, 0.77) 30%, rgba(255, 255, 255, 0));
}

.index-banner ul li:last-child {
  width: 50%;
}

@media (max-width: 767px) {
  .index-banner ul li:last-child {
    width: 100%;
  }
}

.index-banner ul li:last-child a .mask {
  background-image: linear-gradient(180deg, rgba(199, 148, 90, 0.77) 30%, rgba(255, 255, 255, 0));
}

.index-banner ul li:hover:not(:nth-child(8)) a img {
  opacity: 0;
}

.index-banner ul li:hover:not(:nth-child(8)) a video {
  opacity: 1;
}

.index-banner ul li:hover a img {
  transform: scale(1.1);
}

.index-banner ul li a {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.index-banner ul li a img {
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
  object-fit: cover;
  transition: 0.5s;
  position: absolute;
  top: 0;
  left: 0;
}

.index-banner ul li a video {
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
  object-fit: cover;
  opacity: 0;
  transition: 0.5s;
}

.index-banner ul li a .mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.index-banner ul li a .mask p {
  font-size: 0.3rem;
  color: #fff;
}

@media (max-width: 767px) {
  .index-banner ul li a .mask p {
    font-size: 14px;
  }
}

.section1 {
  position: relative;
  padding-top: 1.15rem;
  padding-bottom: 330px;
}

@media (max-width: 767px) {
  .section1 {
    padding-top: 30px;
  }
}

@media (max-width: 1599px) {
  .section1 {
    padding-bottom: 220px;
  }
}

@media (max-width: 1359px) {
  .section1 {
    padding-bottom: 170px;
  }
}

@media (max-width: 1024px) {
  .section1 {
    padding-bottom: 130px;
  }
}

@media (max-width: 991px) {
  .section1 {
    padding-bottom: 80px;
  }
}

@media (max-width: 767px) {
  .section1 {
    padding-bottom: 0;
  }
}

.section1::after {
  content: '';
  display: block;
  width: 100%;
  background: #62a937;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  height: 180px;
}

@media (max-width: 1689px) {
  .section1::after {
    height: 150px;
  }
}

@media (max-width: 1599px) {
  .section1::after {
    height: 120px;
  }
}

@media (max-width: 1359px) {
  .section1::after {
    height: 90px;
  }
}

@media (max-width: 1024px) {
  .section1::after {
    height: 60px;
  }
}

@media (max-width: 991px) {
  .section1::after {
    height: 30px;
  }
}

@media (max-width: 767px) {
  .section1::after {
    height: 0px;
  }
}

.section1 .left {
  position: relative;
  z-index: 1;
}

.section1 .left .title {
  line-height: 1;
  font-size: 0.4rem;
  margin-bottom: 0.55rem;
}

@media (max-width: 767px) {
  .section1 .left .title {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .section1 .left .title {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .section1 .left .title {
    margin-bottom: 15px;
  }
}

.section1 .left .title p {
  margin-top: 10px;
  font-size: 0.6rem;
}

@media (max-width: 767px) {
  .section1 .left .title p {
    font-size: 20px;
  }
}

.section1 .left .desc {
  width: 370px;
  padding-right: 1.1rem;
  font-size: 16px;
  margin-bottom: 0.5rem;
}

@media (max-width: 767px) {
  .section1 .left .desc {
    padding-right: 10px;
  }
}

@media (max-width: 1689px) {
  .section1 .left .desc {
    font-size: 16px;
  }
}

@media (max-width: 1599px) {
  .section1 .left .desc {
    font-size: 16px;
  }
}

@media (max-width: 1359px) {
  .section1 .left .desc {
    font-size: 16px;
  }
}

@media (max-width: 1024px) {
  .section1 .left .desc {
    font-size: 16px;
  }
}

@media (max-width: 991px) {
  .section1 .left .desc {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .section1 .left .desc {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .section1 .left .desc {
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .section1 .left .link {
    text-align: center;
  }
}

.section1 .left .link a {
  display: inline-block;
  border: 1px solid #000;
  color: #666666;
  transition: all .25s;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
}

@media (max-width: 1689px) {
  .section1 .left .link a {
    padding-left: 23.33333px;
  }
}

@media (max-width: 1599px) {
  .section1 .left .link a {
    padding-left: 21.66667px;
  }
}

@media (max-width: 1359px) {
  .section1 .left .link a {
    padding-left: 20px;
  }
}

@media (max-width: 1024px) {
  .section1 .left .link a {
    padding-left: 18.33333px;
  }
}

@media (max-width: 991px) {
  .section1 .left .link a {
    padding-left: 16.66667px;
  }
}

@media (max-width: 767px) {
  .section1 .left .link a {
    padding-left: 15px;
  }
}

@media (max-width: 1689px) {
  .section1 .left .link a {
    padding-right: 23.33333px;
  }
}

@media (max-width: 1599px) {
  .section1 .left .link a {
    padding-right: 21.66667px;
  }
}

@media (max-width: 1359px) {
  .section1 .left .link a {
    padding-right: 20px;
  }
}

@media (max-width: 1024px) {
  .section1 .left .link a {
    padding-right: 18.33333px;
  }
}

@media (max-width: 991px) {
  .section1 .left .link a {
    padding-right: 16.66667px;
  }
}

@media (max-width: 767px) {
  .section1 .left .link a {
    padding-right: 15px;
  }
}

@media (max-width: 1689px) {
  .section1 .left .link a {
    padding-top: 5px;
  }
}

@media (max-width: 1599px) {
  .section1 .left .link a {
    padding-top: 5px;
  }
}

@media (max-width: 1359px) {
  .section1 .left .link a {
    padding-top: 5px;
  }
}

@media (max-width: 1024px) {
  .section1 .left .link a {
    padding-top: 5px;
  }
}

@media (max-width: 991px) {
  .section1 .left .link a {
    padding-top: 5px;
  }
}

@media (max-width: 767px) {
  .section1 .left .link a {
    padding-top: 5px;
  }
}

@media (max-width: 1689px) {
  .section1 .left .link a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1599px) {
  .section1 .left .link a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1359px) {
  .section1 .left .link a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1024px) {
  .section1 .left .link a {
    padding-bottom: 5px;
  }
}

@media (max-width: 991px) {
  .section1 .left .link a {
    padding-bottom: 5px;
  }
}

@media (max-width: 767px) {
  .section1 .left .link a {
    padding-bottom: 5px;
  }
}

.section1 .left .link a:hover {
  background: #62a937;
  border: 1px solid #fff;
  color: #fff;
}

.section1 .right {
  bottom: 0;
  right: 0;
  z-index: 0;
  margin-top: 0;
  position: absolute;
  width: 55.7%;
}

@media (max-width: 767px) {
  .section1 .right {
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .section1 .right {
    position: static;
  }
}

@media (max-width: 991px) {
  .section1 .right {
    width: 53%;
  }
}

@media (max-width: 767px) {
  .section1 .right {
    width: 100%;
  }
}

.section1 .right img {
  display: block;
  width: 100%;
  height: auto;
}

.section2 {
  margin-top: 1.3rem;
  margin-bottom: 1.4rem;
}

@media (max-width: 767px) {
  .section2 {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .section2 {
    margin-bottom: 30px;
  }
}

.section2 .title {
  text-align: center;
  font-size: 0.6rem;
  margin-bottom: 0.5rem;
}

@media (max-width: 767px) {
  .section2 .title {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .section2 .title {
    margin-bottom: 10px;
  }
}

.section2 .swiper-container {
  height: 5.6rem;
}

@media (max-width: 767px) {
  .section2 .swiper-container {
    height: 150px;
  }
}

.section2 .swiper-container .swiper-slide {
  transition: all .8s;
  transform: scale(0.8);
  position: relative;
}

.section2 .swiper-container .swiper-slide.swiper-slide-duplicate-active, .section2 .swiper-container .swiper-slide.swiper-slide-active {
  transform: scale(1);
}

.section2 .swiper-container .swiper-slide.swiper-slide-duplicate-active a .item-desc span:nth-child(1), .section2 .swiper-container .swiper-slide.swiper-slide-active a .item-desc span:nth-child(1) {
  top: 42%;
  left: 41%;
}

.section2 .swiper-container .swiper-slide.swiper-slide-duplicate-active a .item-desc span:nth-child(2), .section2 .swiper-container .swiper-slide.swiper-slide-active a .item-desc span:nth-child(2) {
  top: 42%;
  left: 46%;
}

.section2 .swiper-container .swiper-slide.swiper-slide-duplicate-active a .item-desc span:nth-child(3), .section2 .swiper-container .swiper-slide.swiper-slide-active a .item-desc span:nth-child(3) {
  top: 42%;
  left: 51%;
}

.section2 .swiper-container .swiper-slide.swiper-slide-duplicate-active a .item-desc span:nth-child(4), .section2 .swiper-container .swiper-slide.swiper-slide-active a .item-desc span:nth-child(4) {
  top: 42%;
  left: 56%;
}

.section2 .swiper-container .swiper-slide.swiper-slide-duplicate-active a .item-title, .section2 .swiper-container .swiper-slide.swiper-slide-duplicate-active a .item-desc, .section2 .swiper-container .swiper-slide.swiper-slide-active a .item-title, .section2 .swiper-container .swiper-slide.swiper-slide-active a .item-desc {
  opacity: 1;
}

.section2 .swiper-container .swiper-slide.swiper-slide-prev::after, .section2 .swiper-container .swiper-slide.swiper-slide-next::after {
  content: '';
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
}

.section2 .swiper-container .swiper-slide a {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-position: center;
  background-size: cover;
  align-items: center;
  justify-content: center;
  color: #f5fbf9;
  position: relative;
}

.section2 .swiper-container .swiper-slide a::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.section2 .swiper-container .swiper-slide a .item-title {
  opacity: 0;
  transition: all .5s;
  position: relative;
  z-index: 1;
  font-size: 0.3rem;
}

@media (max-width: 767px) {
  .section2 .swiper-container .swiper-slide a .item-title {
    font-size: 16px;
  }
}

.section2 .swiper-container .swiper-slide a .item-desc {
  transition: all .5s;
  font-size: 0.4rem;
}

@media (max-width: 767px) {
  .section2 .swiper-container .swiper-slide a .item-desc {
    font-size: 20px;
  }
}

.section2 .swiper-container .swiper-slide a .item-desc span {
  z-index: 1;
  transition: all .35s ease-in-out;
  position: absolute;
  color: #fff;
}

@media (max-width: 1024px) {
  .section2 .swiper-container .swiper-slide a .item-desc span {
    position: static;
  }
}

.section2 .swiper-container .swiper-slide a .item-desc span:nth-child(1) {
  top: 2%;
  left: 3%;
}

.section2 .swiper-container .swiper-slide a .item-desc span:nth-child(2) {
  top: 2%;
  left: 93%;
}

.section2 .swiper-container .swiper-slide a .item-desc span:nth-child(3) {
  top: 84%;
  left: 3%;
}

.section2 .swiper-container .swiper-slide a .item-desc span:nth-child(4) {
  top: 84%;
  left: 93%;
}

.section2 .swiper-button-prev {
  left: 26%;
}

@media (max-width: 1689px) {
  .section2 .swiper-button-prev {
    left: 22%;
  }
}

@media (max-width: 1599px) {
  .section2 .swiper-button-prev {
    left: 18%;
  }
}

@media (max-width: 1359px) {
  .section2 .swiper-button-prev {
    left: 14%;
  }
}

@media (max-width: 1024px) {
  .section2 .swiper-button-prev {
    left: 10%;
  }
}

@media (max-width: 991px) {
  .section2 .swiper-button-prev {
    left: 6%;
  }
}

@media (max-width: 767px) {
  .section2 .swiper-button-prev {
    left: 2%;
  }
}

.section2 .swiper-button-next {
  right: 26%;
}

@media (max-width: 1689px) {
  .section2 .swiper-button-next {
    right: 22%;
  }
}

@media (max-width: 1599px) {
  .section2 .swiper-button-next {
    right: 18%;
  }
}

@media (max-width: 1359px) {
  .section2 .swiper-button-next {
    right: 14%;
  }
}

@media (max-width: 1024px) {
  .section2 .swiper-button-next {
    right: 10%;
  }
}

@media (max-width: 991px) {
  .section2 .swiper-button-next {
    right: 6%;
  }
}

@media (max-width: 767px) {
  .section2 .swiper-button-next {
    right: 2%;
  }
}

.section2 .swiper-button-prev, .section2 .swiper-button-next {
  color: #fff;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

@media (max-width: 1689px) {
  .section2 .swiper-button-prev, .section2 .swiper-button-next {
    width: 46.66667px;
  }
}

@media (max-width: 1599px) {
  .section2 .swiper-button-prev, .section2 .swiper-button-next {
    width: 43.33333px;
  }
}

@media (max-width: 1359px) {
  .section2 .swiper-button-prev, .section2 .swiper-button-next {
    width: 40px;
  }
}

@media (max-width: 1024px) {
  .section2 .swiper-button-prev, .section2 .swiper-button-next {
    width: 36.66667px;
  }
}

@media (max-width: 991px) {
  .section2 .swiper-button-prev, .section2 .swiper-button-next {
    width: 33.33333px;
  }
}

@media (max-width: 767px) {
  .section2 .swiper-button-prev, .section2 .swiper-button-next {
    width: 30px;
  }
}

@media (max-width: 1689px) {
  .section2 .swiper-button-prev, .section2 .swiper-button-next {
    height: 46.66667px;
  }
}

@media (max-width: 1599px) {
  .section2 .swiper-button-prev, .section2 .swiper-button-next {
    height: 43.33333px;
  }
}

@media (max-width: 1359px) {
  .section2 .swiper-button-prev, .section2 .swiper-button-next {
    height: 40px;
  }
}

@media (max-width: 1024px) {
  .section2 .swiper-button-prev, .section2 .swiper-button-next {
    height: 36.66667px;
  }
}

@media (max-width: 991px) {
  .section2 .swiper-button-prev, .section2 .swiper-button-next {
    height: 33.33333px;
  }
}

@media (max-width: 767px) {
  .section2 .swiper-button-prev, .section2 .swiper-button-next {
    height: 30px;
  }
}

.section2 .swiper-button-next:after, .section2 .swiper-button-prev:after {
  font-size: 20px;
}

@media (max-width: 1689px) {
  .section2 .swiper-button-next:after, .section2 .swiper-button-prev:after {
    font-size: 18.66667px;
  }
}

@media (max-width: 1599px) {
  .section2 .swiper-button-next:after, .section2 .swiper-button-prev:after {
    font-size: 17.33333px;
  }
}

@media (max-width: 1359px) {
  .section2 .swiper-button-next:after, .section2 .swiper-button-prev:after {
    font-size: 16px;
  }
}

@media (max-width: 1024px) {
  .section2 .swiper-button-next:after, .section2 .swiper-button-prev:after {
    font-size: 14.66667px;
  }
}

@media (max-width: 991px) {
  .section2 .swiper-button-next:after, .section2 .swiper-button-prev:after {
    font-size: 13.33333px;
  }
}

@media (max-width: 767px) {
  .section2 .swiper-button-next:after, .section2 .swiper-button-prev:after {
    font-size: 12px;
  }
}

.section3 {
  position: relative;
  margin-bottom: 1.5rem;
  padding-bottom: 290px;
}

@media (max-width: 767px) {
  .section3 {
    margin-bottom: 30px;
  }
}

@media (max-width: 1599px) {
  .section3 {
    padding-bottom: 190px;
  }
}

@media (max-width: 1359px) {
  .section3 {
    padding-bottom: 170px;
  }
}

@media (max-width: 1024px) {
  .section3 {
    padding-bottom: 130px;
  }
}

@media (max-width: 991px) {
  .section3 {
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .section3 {
    padding-bottom: 0;
  }
}

.section3::after {
  content: '';
  display: block;
  width: 100%;
  background: #62a937;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  mix-blend-mode: multiply;
  height: 1.4rem;
}

@media (max-width: 767px) {
  .section3::after {
    height: 50px;
  }
}

.section3 .left-img {
  bottom: 0;
  left: 0;
  z-index: 0;
  margin-top: 0;
  position: absolute;
  width: 56.25%;
}

@media (max-width: 767px) {
  .section3 .left-img {
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .section3 .left-img {
    position: static;
  }
}

@media (max-width: 991px) {
  .section3 .left-img {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .section3 .left-img {
    width: 100%;
  }
}

.section3 .left-img img {
  display: block;
  width: 100%;
  height: auto;
}

.section3 .container-fluid {
  display: flex;
  justify-content: flex-end;
}

.section3 .right-text {
  position: relative;
  z-index: 1;
  width: 370px;
}

@media (max-width: 1599px) {
  .section3 .right-text {
    width: 420px;
  }
}

@media (max-width: 1359px) {
  .section3 .right-text {
    width: 470px;
  }
}

@media (max-width: 1024px) {
  .section3 .right-text {
    width: 370px;
  }
}

@media (max-width: 991px) {
  .section3 .right-text {
    width: 330px;
  }
}

@media (max-width: 767px) {
  .section3 .right-text {
    width: 100%;
  }
}

.section3 .right-text .title {
  color: #62a937;
  font-size: 0.4rem;
}

@media (max-width: 767px) {
  .section3 .right-text .title {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .section3 .right-text .title {
    font-size: 20px;
  }
}

.section3 .right-text .desc {
  font-size: 16px;
  margin-bottom: 0.5rem;
}

@media (max-width: 1689px) {
  .section3 .right-text .desc {
    font-size: 16px;
  }
}

@media (max-width: 1599px) {
  .section3 .right-text .desc {
    font-size: 16px;
  }
}

@media (max-width: 1359px) {
  .section3 .right-text .desc {
    font-size: 16px;
  }
}

@media (max-width: 1024px) {
  .section3 .right-text .desc {
    font-size: 16px;
  }
}

@media (max-width: 991px) {
  .section3 .right-text .desc {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .section3 .right-text .desc {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .section3 .right-text .desc {
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .section3 .right-text .link {
    text-align: center;
  }
}

.section3 .right-text .link a {
  display: inline-block;
  border: 1px solid #000;
  color: #666666;
  transition: all .25s;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
}

@media (max-width: 1689px) {
  .section3 .right-text .link a {
    padding-left: 23.33333px;
  }
}

@media (max-width: 1599px) {
  .section3 .right-text .link a {
    padding-left: 21.66667px;
  }
}

@media (max-width: 1359px) {
  .section3 .right-text .link a {
    padding-left: 20px;
  }
}

@media (max-width: 1024px) {
  .section3 .right-text .link a {
    padding-left: 18.33333px;
  }
}

@media (max-width: 991px) {
  .section3 .right-text .link a {
    padding-left: 16.66667px;
  }
}

@media (max-width: 767px) {
  .section3 .right-text .link a {
    padding-left: 15px;
  }
}

@media (max-width: 1689px) {
  .section3 .right-text .link a {
    padding-right: 23.33333px;
  }
}

@media (max-width: 1599px) {
  .section3 .right-text .link a {
    padding-right: 21.66667px;
  }
}

@media (max-width: 1359px) {
  .section3 .right-text .link a {
    padding-right: 20px;
  }
}

@media (max-width: 1024px) {
  .section3 .right-text .link a {
    padding-right: 18.33333px;
  }
}

@media (max-width: 991px) {
  .section3 .right-text .link a {
    padding-right: 16.66667px;
  }
}

@media (max-width: 767px) {
  .section3 .right-text .link a {
    padding-right: 15px;
  }
}

@media (max-width: 1689px) {
  .section3 .right-text .link a {
    padding-top: 5px;
  }
}

@media (max-width: 1599px) {
  .section3 .right-text .link a {
    padding-top: 5px;
  }
}

@media (max-width: 1359px) {
  .section3 .right-text .link a {
    padding-top: 5px;
  }
}

@media (max-width: 1024px) {
  .section3 .right-text .link a {
    padding-top: 5px;
  }
}

@media (max-width: 991px) {
  .section3 .right-text .link a {
    padding-top: 5px;
  }
}

@media (max-width: 767px) {
  .section3 .right-text .link a {
    padding-top: 5px;
  }
}

@media (max-width: 1689px) {
  .section3 .right-text .link a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1599px) {
  .section3 .right-text .link a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1359px) {
  .section3 .right-text .link a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1024px) {
  .section3 .right-text .link a {
    padding-bottom: 5px;
  }
}

@media (max-width: 991px) {
  .section3 .right-text .link a {
    padding-bottom: 5px;
  }
}

@media (max-width: 767px) {
  .section3 .right-text .link a {
    padding-bottom: 5px;
  }
}

.section3 .right-text .link a:hover {
  background: #62a937;
  border: 1px solid #fff;
  color: #fff;
}

.section4 {
  position: relative;
  padding-bottom: 0.9rem;
}

@media (max-width: 767px) {
  .section4 {
    padding-bottom: 10px;
  }
}

.section4::after {
  content: '';
  display: block;
  width: 100%;
  background-color: #eaeaea;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  height: 50%;
}

.section4 .title {
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 0.6rem;
}

@media (max-width: 767px) {
  .section4 .title {
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .section4 .title {
    font-size: 20px;
  }
}

.section4 .news-list {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

@media (max-width: 767px) {
  .section4 .news-list {
    padding-left: 10px;
  }
}

@media (max-width: 767px) {
  .section4 .news-list {
    padding-right: 10px;
  }
}

.section4 .news-list ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.section4 .news-list ul li {
  margin-bottom: 0.9rem;
  width: 30%;
  transition: 0.5s;
}

@media (max-width: 767px) {
  .section4 .news-list ul li {
    margin-bottom: 20px;
  }
}

@media (max-width: 991px) {
  .section4 .news-list ul li {
    width: 48%;
  }
}

.section4 .news-list ul li a {
  background: #f1f1f1;
  display: flex;
  flex-flow: column;
  width: 100%;
}

.section4 .news-list ul li a .left-img {
  flex-shrink: 0;
  background-position: center;
  background-size: cover;
  width: 100%;
}

.section4 .news-list ul li a .left-img img {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  opacity: 0;
}

.section4 .news-list ul li a .right-text {
  width: 100%;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
  padding-left: 20px;
}

@media (max-width: 1689px) {
  .section4 .news-list ul li a .right-text {
    padding-top: 18.33333px;
  }
}

@media (max-width: 1599px) {
  .section4 .news-list ul li a .right-text {
    padding-top: 16.66667px;
  }
}

@media (max-width: 1359px) {
  .section4 .news-list ul li a .right-text {
    padding-top: 15px;
  }
}

@media (max-width: 1024px) {
  .section4 .news-list ul li a .right-text {
    padding-top: 13.33333px;
  }
}

@media (max-width: 991px) {
  .section4 .news-list ul li a .right-text {
    padding-top: 11.66667px;
  }
}

@media (max-width: 767px) {
  .section4 .news-list ul li a .right-text {
    padding-top: 10px;
  }
}

@media (max-width: 1689px) {
  .section4 .news-list ul li a .right-text {
    padding-right: 18.33333px;
  }
}

@media (max-width: 1599px) {
  .section4 .news-list ul li a .right-text {
    padding-right: 16.66667px;
  }
}

@media (max-width: 1359px) {
  .section4 .news-list ul li a .right-text {
    padding-right: 15px;
  }
}

@media (max-width: 1024px) {
  .section4 .news-list ul li a .right-text {
    padding-right: 13.33333px;
  }
}

@media (max-width: 991px) {
  .section4 .news-list ul li a .right-text {
    padding-right: 11.66667px;
  }
}

@media (max-width: 767px) {
  .section4 .news-list ul li a .right-text {
    padding-right: 10px;
  }
}

@media (max-width: 1689px) {
  .section4 .news-list ul li a .right-text {
    padding-bottom: 27.5px;
  }
}

@media (max-width: 1599px) {
  .section4 .news-list ul li a .right-text {
    padding-bottom: 25px;
  }
}

@media (max-width: 1359px) {
  .section4 .news-list ul li a .right-text {
    padding-bottom: 22.5px;
  }
}

@media (max-width: 1024px) {
  .section4 .news-list ul li a .right-text {
    padding-bottom: 20px;
  }
}

@media (max-width: 991px) {
  .section4 .news-list ul li a .right-text {
    padding-bottom: 17.5px;
  }
}

@media (max-width: 767px) {
  .section4 .news-list ul li a .right-text {
    padding-bottom: 15px;
  }
}

@media (max-width: 1689px) {
  .section4 .news-list ul li a .right-text {
    padding-left: 18.33333px;
  }
}

@media (max-width: 1599px) {
  .section4 .news-list ul li a .right-text {
    padding-left: 16.66667px;
  }
}

@media (max-width: 1359px) {
  .section4 .news-list ul li a .right-text {
    padding-left: 15px;
  }
}

@media (max-width: 1024px) {
  .section4 .news-list ul li a .right-text {
    padding-left: 13.33333px;
  }
}

@media (max-width: 991px) {
  .section4 .news-list ul li a .right-text {
    padding-left: 11.66667px;
  }
}

@media (max-width: 767px) {
  .section4 .news-list ul li a .right-text {
    padding-left: 10px;
  }
}

.section4 .news-list ul li a .right-text .item-date {
  text-align: center;
}

.section4 .news-list ul li a .right-text .item-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-height: 1.4;
  min-height: 66px;
  -webkit-line-clamp: 2;
  margin-top: 15px;
  font-size: 0.2rem;
  text-align: center;
}

@media (max-width: 1689px) {
  .section4 .news-list ul li a .right-text .item-title {
    min-height: 63px;
  }
}

@media (max-width: 1599px) {
  .section4 .news-list ul li a .right-text .item-title {
    min-height: 60px;
  }
}

@media (max-width: 1359px) {
  .section4 .news-list ul li a .right-text .item-title {
    min-height: 57px;
  }
}

@media (max-width: 1024px) {
  .section4 .news-list ul li a .right-text .item-title {
    min-height: 54px;
  }
}

@media (max-width: 991px) {
  .section4 .news-list ul li a .right-text .item-title {
    min-height: 51px;
  }
}

@media (max-width: 767px) {
  .section4 .news-list ul li a .right-text .item-title {
    min-height: 48px;
  }
}

@media (max-width: 767px) {
  .section4 .news-list ul li a .right-text .item-title {
    -webkit-line-clamp: 1;
  }
}

@media (max-width: 1689px) {
  .section4 .news-list ul li a .right-text .item-title {
    margin-top: 14.16667px;
  }
}

@media (max-width: 1599px) {
  .section4 .news-list ul li a .right-text .item-title {
    margin-top: 13.33333px;
  }
}

@media (max-width: 1359px) {
  .section4 .news-list ul li a .right-text .item-title {
    margin-top: 12.5px;
  }
}

@media (max-width: 1024px) {
  .section4 .news-list ul li a .right-text .item-title {
    margin-top: 11.66667px;
  }
}

@media (max-width: 991px) {
  .section4 .news-list ul li a .right-text .item-title {
    margin-top: 10.83333px;
  }
}

@media (max-width: 767px) {
  .section4 .news-list ul li a .right-text .item-title {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .section4 .news-list ul li a .right-text .item-title {
    font-size: 16px;
  }
}

@media screen and (max-width: 640px) {
  .section4 .news-list ul li a .right-text .item-title {
    min-height: auto;
  }
}

.section4 .news-list ul li a .right-text .item-desc {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  margin-top: 0.3rem;
  font-size: 14px;
  text-align: center;
}

@media (max-width: 767px) {
  .section4 .news-list ul li a .right-text .item-desc {
    -webkit-line-clamp: 2;
  }
}

@media (max-width: 767px) {
  .section4 .news-list ul li a .right-text .item-desc {
    margin-top: 15px;
  }
}

@media (max-width: 1689px) {
  .section4 .news-list ul li a .right-text .item-desc {
    font-size: 14px;
  }
}

@media (max-width: 1599px) {
  .section4 .news-list ul li a .right-text .item-desc {
    font-size: 14px;
  }
}

@media (max-width: 1359px) {
  .section4 .news-list ul li a .right-text .item-desc {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  .section4 .news-list ul li a .right-text .item-desc {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .section4 .news-list ul li a .right-text .item-desc {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .section4 .news-list ul li a .right-text .item-desc {
    font-size: 14px;
  }
}

.section4 .news-list ul li a .right-text .item-line {
  width: 20%;
  height: 2px;
  background: #000;
  margin: 20px auto 0;
}

.section4 .news-list ul li a .right-text .item-more {
  width: max-content;
  line-height: 2.6;
  text-align: center;
  color: #000;
  background: #fff;
  margin: 20px auto 0;
  font-size: 14px;
  border: 1px solid #000000;
  transition: 0.5s;
  padding: 0 22px;
}

@media (max-width: 1689px) {
  .section4 .news-list ul li a .right-text .item-more {
    font-size: 14px;
  }
}

@media (max-width: 1599px) {
  .section4 .news-list ul li a .right-text .item-more {
    font-size: 14px;
  }
}

@media (max-width: 1359px) {
  .section4 .news-list ul li a .right-text .item-more {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  .section4 .news-list ul li a .right-text .item-more {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .section4 .news-list ul li a .right-text .item-more {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .section4 .news-list ul li a .right-text .item-more {
    font-size: 14px;
  }
}

.section4 .news-list ul li:hover {
  transform: scale(1.05);
}

.section4 .news-list ul li:hover a .right-text .item-more {
  border: 1px solid #62A937;
  background: #62A937;
  color: #fff;
}

.section4 .news-list ul .placeholder {
  width: 30%;
}

@media (max-width: 991px) {
  .section4 .news-list ul .placeholder {
    width: 48%;
  }
}

.section4 .more-link {
  text-align: center;
  font-weight: bold;
  line-height: 2.2;
}

.section4 .more-link a {
  display: inline-block;
  border: 1px solid #62a937;
  transition: all .3s;
  color: #62a937;
  padding-left: 25px;
  padding-right: 25px;
}

@media (max-width: 1689px) {
  .section4 .more-link a {
    padding-left: 22.5px;
  }
}

@media (max-width: 1599px) {
  .section4 .more-link a {
    padding-left: 20px;
  }
}

@media (max-width: 1359px) {
  .section4 .more-link a {
    padding-left: 17.5px;
  }
}

@media (max-width: 1024px) {
  .section4 .more-link a {
    padding-left: 15px;
  }
}

@media (max-width: 991px) {
  .section4 .more-link a {
    padding-left: 12.5px;
  }
}

@media (max-width: 767px) {
  .section4 .more-link a {
    padding-left: 10px;
  }
}

@media (max-width: 1689px) {
  .section4 .more-link a {
    padding-right: 22.5px;
  }
}

@media (max-width: 1599px) {
  .section4 .more-link a {
    padding-right: 20px;
  }
}

@media (max-width: 1359px) {
  .section4 .more-link a {
    padding-right: 17.5px;
  }
}

@media (max-width: 1024px) {
  .section4 .more-link a {
    padding-right: 15px;
  }
}

@media (max-width: 991px) {
  .section4 .more-link a {
    padding-right: 12.5px;
  }
}

@media (max-width: 767px) {
  .section4 .more-link a {
    padding-right: 10px;
  }
}

.section4 .more-link a:hover {
  color: #fff;
  background: #62a937;
}
